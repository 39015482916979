// @flow
import * as React from 'react'
import { Router, Redirect } from '@reach/router'

export const REDIRECTS = [
  {
    name: 'kicks',
    to: '/products/kicks'
  },
  {
    name: '3fm-festival',
    to: '/products/3fm'
  },
  {
    name: 'worldwidefm',
    to: '/products/wwfm'
  },
  {
    name: '22tracks',
    to: '/products/22tracks'
  },
  {
    name: 'npo-chatbot-duco',
    to: '/experiences/duco'
  },
  {
    name: 'transmigration',
    to: '/experiences/morton-f'
  },
  {
    name: 'efteling-zoetrope',
    to: '/experiences/zoetrope'
  },
  {
    name: 'octomadness',
    to: '/experiments/octomadness'
  },
  {
    name: 'joep-beving',
    to: '/experiments/sleeping-lotus'
  },
  {
    name: 'the-arcade-ar',
    to: '/experiments/the-arcade'
  },
  {
    name: 'augmented-audio-a-storytelling-platform',
    to: '/other/augmented-audio-a-storytelling-platform'
  }
]

const Redirects = ({ projectName }) => {
  const project = REDIRECTS.find(redirect => redirect.name === projectName)
  if (!project) return <Redirect noThrow to="/products" />
  return <Redirect noThrow to={project.to} />
}

export default ({ pathPattern = '/projects/:projectName' }) => (
  <Router>
    <Redirects path={pathPattern} />
    <Redirects path="*" />
  </Router>
)
